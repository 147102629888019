const common = {
    lookerConfig: {
        dashboardId: 1026,
        withDialogScroll: true,
        withDynamicIFrameHeight: true,
        withScrollMonitor: true,
        withAllowAttr: 'fullscreen',
        withClassName: 'looker-embed',
        appendTo: '#looker-dashboard-im',
        host: 'xad.cloud.looker.com:19999'
    },
    backend_app: 'INSIGHTS',
    backend_key: 'CwMkCuv3LfBLWtGctE5N3cBlr9pXhY03homlo4as3T6HcJyM',
    sentryDSN: 'https://3ec75c1532d94d2b9ac9ef2b9121f592@apps-sentry.groundtruth.com/53'
};

const local: Config = {
    ...common,
    env: 'local',
    loginUrl: 'http://localhost.groundtruth.com:8080?app=im&return_url=',
    logoutUrl: 'http://localhost.groundtruth.com:8080/logout?app=im&return_url=http://localhost.groundtruth.com:3000',
    apiHostname: 'http://localhost:1614',
    discoveryUrl: 'https://location-software-np.groundtruth.com',
    locationManagerUrl: 'https://locations-develop.groundtruth.com',
    audienceManagerUrl: 'https://audience-staging.groundtruth.com',
    adsManagerUrl: 'https://ads-develop.groundtruth.com/'
};

const develop: Config = {
    ...common,
    env: 'staging',
    loginUrl: 'https://login-staging.groundtruth.com?app=im&return_url=',
    logoutUrl:
        'https://login-staging.groundtruth.com/logout?app=im&return_url=https://insights-develop.groundtruth.com',
    apiHostname: 'https://insights-internal-develop.groundtruth.com',
    discoveryUrl: 'https://location-software-np.groundtruth.com',
    locationManagerUrl: 'https://locations-develop.groundtruth.com',
    audienceManagerUrl: 'https://audience-staging.groundtruth.com',
    adsManagerUrl: 'https://ads-develop.groundtruth.com/'
};

const staging: Config = {
    ...common,
    env: 'staging',
    loginUrl: 'https://login-staging.groundtruth.com?app=im&return_url=',
    logoutUrl:
        'https://login-staging.groundtruth.com/logout?app=im&return_url=https://insights-np.groundtruth.com',
    apiHostname: 'https://insights-internal-np.groundtruth.com',
    discoveryUrl: 'https://location-software-np.groundtruth.com',
    locationManagerUrl: 'https://locations-develop.groundtruth.com',
    audienceManagerUrl: 'https://audience-staging.groundtruth.com',
    adsManagerUrl: 'https://ads-develop.groundtruth.com/'
};

const prod: Config = {
    ...common,
    env: 'prod',
    loginUrl: 'https://login.groundtruth.com?app=im&return_url=',
    logoutUrl: 'https://login.groundtruth.com/logout?app=im&return_url=https://insights.groundtruth.com',
    apiHostname: 'https://insights-internal.groundtruth.com',
    discoveryUrl: 'https://location-software.groundtruth.com',
    locationManagerUrl: 'https://locations.groundtruth.com',
    audienceManagerUrl: 'https://audience.groundtruth.com',
    adsManagerUrl: 'https://ads.groundtruth.com/'
};

const config = (): Config => {
    const configs: { [key: string]: Config } = {
        prod,
        staging,
        local,
        develop
    };
    return (process.env.REACT_APP_ENV && configs[process.env.REACT_APP_ENV]) || local;
};

export default config();
